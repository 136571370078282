.title-form {
    width: 110px;
    margin: 20px 0px;
}

.box-upload {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 5px;
}
.box-upload #upload_img{
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    cursor: pointer;
}

.box-upload #img_preview{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    object-fit: cover;
}
.box-upload:hover .box-icon-upload {
    opacity: 1;
}
.box-icon-upload {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    background: #ccccccc2;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    opacity: 0;
}
.box-icon-upload i {
    color: white;
    font-size: 20px;
}

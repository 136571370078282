.table{
  th{
    text-align: left;
  }
  td{
    text-align: left;
  }

}
.form-check-input:checked {
  background-color: #556ee6 !important;
}
img{max-width: 100%;}
.table-striped{
  tbody{
    tr:nth-of-type(odd){
      *{
        --bs-table-accent-bg: #F5F5F5 !important;
      }
    }
    tr{
      td{
        border: none !important;
      }
    }
  }
  --bs-table-accent-bg: #F5F5F5;
  color: var(--bs-table-striped-color);
}
.bordercol{
  border: 1px solid #BFBFBF;
  padding:12px;
  box-sizing: border-box;
  border-radius: 4px;
  .search-box{
    width: 100%;
    text-align: center;
    .search-label {
      float: none;
      width: 100%;
    }
    .form-control{
      width: 100%;
    }
  }

}
.table-rtl{
  direction: rtl;
}
.fontCollapse{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}
.limitheight{
  max-height: 608px;
  min-height: 608px;
}
.collapseRow{
  transition: all 0.4s ease 0s;
  .collapseRowHeader{
    padding: 14px;
    display: flex;
    background: #F5F5F5;
    cursor: pointer;
    .collapseRowHeaderLabel{
      flex: 1;
    }
    .collapseArrow{
      transition: transform .24s;
    }

  }
  .collapseRowBody{

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .collapseRowHeader.active{
    .collapseArrow{
      transform: rotate(90deg);
    }
  }
}

.collapseRowRaw{
  padding: 14px;
  display: flex;
  label{
    flex: 1;
  }

}

@page {
  margin: 0;
  width: 148mm;
}

@media print {
  @page {
    margin: 0;
  }

  html,
  body {
    height: 100vh;
    overflow: hidden;
  }

  .print-body thead {
    display: table-row-group
  }

  .print-body #logo {
    margin-top: 0px;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

a {
  color: #5D6975;
  text-decoration: underline;
}

.print-body:last-child {
  page-break-after: auto;
}

.print-body {
  position: relative;
  width: 130mm; 
  height: 209mm;
  margin: 0 auto;
  padding: 4mm 12mm 0 12mm;
  color: #001028;
  background: #FFFFFF !important;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-family: Arial;
}

.print-body header {
  padding: 10px 0;
  margin-bottom: 15px;
}

.print-body #logo {
  text-align: center;
  margin-top: 50px;
}

.print-body #logo img {
  width: 90px;
}

.print-body h1 {
  color: #5D6975;
  font-size: 1.5em;
  font-family: Cambria Math, Cambria, Georgia, serif;
  line-height: 1.4em;
  font-weight: normal;
  text-align: center;
  margin: 0 0 20px 0;
}

.print-body h1 span {
  color: #000;
  font-size: 1.6em;
  font-weight: bold;
}

.print-body #customer {
  float: left;
}

.print-body #customer span {
  color: #5D6975;
  text-align: left;
  width: 55px;
  margin-right: 10px;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 600;
}

.print-body #saleman {
  float: right;
  text-align: right;
  margin-left: 10px;
}

.print-body #saleman span.saleman-title {
  color: #5D6975;
  font-size: 0.8em;
  font-weight: 600;
}

.print-body #customer div,
.print-body #saleman div {
  white-space: nowrap;
}

.print-body table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 10px;
}

.print-body table th,
.print-body table td {
  text-align: center;
  vertical-align: top;
}

.print-body table th {
  padding: 5px;
  color: #5D6975;
  border-bottom: 1px solid #000;
  white-space: nowrap;
  font-weight: Medium;
}

.print-body table .service {
  text-align: center;
}

.print-body table .desc {
  text-align: left;
}

.print-body table .qty,
.print-body table .unit,
.print-body table .total {
  text-align: right;
}

.print-body table td {
  padding: 5px;
  text-align: right;
}

.print-body #notices .notice {
  color: #5D6975;
  font-size: 1.2em;
}

.print-body .footer-addr {
  color: #5D6975;
  width: 100%;
  border-top: 1px solid #C1CED9;
  padding: 8px 0;
  text-align: center;
  font-size: 0.6em;
}

.print-body .bill-summary {
  border-top: 1px solid #C1CED9;
}
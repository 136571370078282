$color-m2: #556ee6;
$color-green: #34c38f;
$color-pink: #f46a6a;
$color-g1: #f5f5f5;

// font and text
.text-right {
  text-align: right;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 18px;
}

h4,
.h4 {
  font-size: 15px;
}

html {
    font-size: 16px;

    // font-family: 'Open Sans', sans-serif !important;
    &:root {
        --bs-font-sans-serif: "Open Sans", sans-serif;
    }
}

body {
  font-size: 14px;
}

.body-2 {
  font-size: 13px;
}

.tag {
  font-size: 12px;
}

.font-weight-bold {
  font-weight: 600;
}

.caption {
  font-size: 11px;
}

.color-primary {
  color: $color-m2;
}

.color-danger {
  color: $color-pink;
}

.color-success {
  color: $color-green;
}

//table
.table-light {
  --bs-table-bg: $color-g1;
}

// btn
.btn-sm {
  padding: 2px 10px;
}

.btn-primary {
  background-color: $color-m2;
}

.btn-success {
  background-color: $color-green;
}

.btn-danger {
  background-color: $color-pink;
}

.cursor-pointer {
  cursor: pointer;
}

// border, layout and background
.main-content {
  overflow: visible;
}
.position-cover {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.my-grid {
    grid-column-gap: 10px;
    grid-row-gap: 16px;
    align-items: center;

    & > * {
        white-space: nowrap;
    }
}

.grid-fit-content-auto {
  grid-template-columns: min-content auto;
}

.grid-auto {
    grid-template-columns: repeat(auto-fit, minmax(300px, calc( 50% - 10px )));
}

.grid-fit-content {
  grid-template-columns: min-content min-content;
}
.grid-2-column-minmax-300-400 {
    grid-template-columns: repeat(2, minmax(300px, 400px));
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mr-5 {
  margin-right: 3rem;
}

.row {
  --bs-gutter-x: 16px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-primary {
  border-color: $color-m2;
}

.border-grey {
    border-color: #BFBFBF !important;
}

.bg-light {
    background-color: #f5f5f5 !important;
}

// component
.image-holder {
  width: 64px;
  .delete-img {
    width: 16px;
    border-radius: 50%;
    height: auto;
    left: 100%;
    transform: translate(-50%, -50%);
    color: red;
    background-color: #fff;
    line-height: 1;
    font-size: 16px;
    cursor: pointer;
  }
}

.upload-image {
  position: relative;
  .delete-img {
    width: 20px;
    border-radius: 50%;
    height: auto;
    top: 0%;
    left: 0%;
    color: red;
    background-color: #fff;
    line-height: 1;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
  }
}

.nowrap-table {
    th,
    td {
        white-space: nowrap;
    }
}

.product-image {
    width: 100px;
}
.role-permission-item {
    padding: .5rem 1rem;
    cursor: pointer;

    &.active {
        background-color: $color-g1;
        color: $color-m2;
    }
}
#layout-wrapper {
    position: relative;
}


// popup phone customer
.order-phone-customer {
    position: relative;
    .order-popup-phone-customer {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 300px;
        overflow: auto;
        z-index: 100;
        background-color: #fff;
        border: 1px solid #ced4da;
    }
    .order-phone-customer-item {
        padding: 10px;
        cursor: pointer;
        &:hover,
        &:focus {
            background-color: #fafafa;
        }
    }
}
.auto-suggest {
    max-width: 200px;
    .searchfield {
        display: block;
        width: 100% !important;
        padding: 0.47rem 0.75rem !important;
        font-size: 0.8125rem !important;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da !important;
        appearance: none;
        border-radius: 0.25rem;
    }
    label {
        display: none !important;
    }
}
.autocompleteSuggestions {
    border: 1px solid #ced4da !important;
    max-width: 840px;
    min-width: 340px;
    width: 100rem !important;
    border-radius: 0.25rem;
    ul li {
        font-size: 0.8125rem !important;
        white-space: pre-wrap !important;
        cursor: pointer !important;
        text-overflow: ellipsis;
        &:hover,
        &:focus {
            background-color: #fafafa;
            color: #495057;
        }
    }
}
.ant-select-item-option-content {
  white-space: pre-wrap !important;
}
.ant-select-dropdown {
  // width: 500px !important;
  font-size: 1.35rem !important;
  max-height: 500px !important;
}
.table-striped.table-bill {
    td,
    th {
        max-width: 200px;
        &:nth-child(1) {
            max-width: 150px;
        }
        &:nth-child(2) {
            width: 250px;
            white-space: normal;
        }
    }
}

.table-striped.table-bill-detail {
    td,
    th {
        &:nth-child(2) {
            max-width: 150px;
            white-space: normal;
        }
    }
}

.mytable {
  background: white !important;
}
.mytable tr:nth-child(2n) {
  background: #f2f2f2 !important;
}
.mytable th {
  background: #eff2f7 !important;
}

.mytable tr:hover {background-color: #ddd;}

.mytable td, .mytable th {
  border: 1px solid #ddd;
  padding: 8px !important;
}
// pagination
.pagination {
    li.page-item:hover,
    li.page-item.highlighted {
        background: none;
    }
}

#tbl-inventory {
  th, td {
    padding: 0.25rem !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
  max-width: fit-content;
  margin: 1.75rem auto;
  }
}

.ant-fix-button-icon {
  vertical-align: middle;
  padding-bottom: 5px;
}

td.accountant-checked {
  background-color: forestgreen;
  color: white;
}

label.ant-form-item-required {
  position: relative;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
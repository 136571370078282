.grid-info-container {
    display: grid;
    grid-template-columns: min-content auto min-content auto;
    grid-column-gap: 10px;
    grid-row-gap: 16px;
    align-items: center;
    & > .grid-info-item {
        white-space: nowrap;
        &:last-child {
            grid-column: 2 / span 3;
        }
    }
}
.form-control-sm {
    min-height: calc(1.5em + 0.5rem + 6px) !important;
}
table td {
    word-wrap : break-word !important;
    white-space: normal !important;
}
.wd-70 {
    width: 75px !important;
}
.ant-switch-checked {
    background-color: #1890ff !important;
}
.ant-switch-small {
    background-color: #bfbfbf;
    .ant-switch-handle {
        border-radius: 50% !important;
    }
}